import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function SectionTitle({ titleKey }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="flex gap-1 h-10">
        <h3 className="text-xl md:text-2xl font-semibold">{t(titleKey)}</h3>
      </div>
      <div className="bg-[#a7dd7b] h-1 w-[18%] md:w-[5%] mb-4"></div>
    </div>
  );
}

function NetworkCard({ image, descriptionKey, link }) {
  const { t } = useTranslation();
  const content = (
    <>
      <img
        src={`/images/${image}`}
        alt={t(descriptionKey)}
        className="w-32 h-16 md:w-48 md:h-24 object-contain mb-4"
      />
      <p className="text-center text-sm md:text-base font-medium text-gray-700">
        {t(descriptionKey)}
      </p>
    </>
  );

  return (
    <div className="flex flex-col items-center bg-white rounded-lg shadow-md p-6">
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="w-full h-full flex flex-col items-center"
        >
          {content}
        </a>
      ) : (
        content
      )}
    </div>
  );
}

function Partner() {
  const { t } = useTranslation();
  const [activeFilter, setActiveFilter] = useState("business");
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const partnersData = {
    business: [
      "partner_meritz.png",
      "partner_akamai.png",
      "partner_groundX.png",
      "partner_uppsala.png",
      "astrip.png",
      "studioNow.png",
      "pinktour.png",
      "TAFISA.png",
    ],
    incubation: [
      "partner_TechPark.png",
      "partner_BITC2.png",
      "partner_bspace.png",
      "partner_nest.png",
      "ventureMark.png",
      "KIPO.png",
      "busan_logo.svg",
    ],
  };

  // Define which logos are square
  const squareLogos = [
    "partner_BITC2.png",
    "partner_bspace.png",
    "partner_nest.png",
  ];

  // Helper function to check if a logo is square
  const isSquareLogo = (filename) => squareLogos.includes(filename);

  const filterButtons = [
    { id: "business", label: t("businessPartner") },
    { id: "incubation", label: t("serviceCollaboro") },
  ];

  const networks = [
    {
      image: "network_GRID.png",
      descriptionKey: "networkGRID",
    },
    {
      image: "network_PRENET.png",
      descriptionKey: "networkPRENET",
      link: "https://console.prenet.blockchainbusan.kr",
    },
    {
      image: "kita.png",
      descriptionKey: "networkKITA",
      link: "https://www.kita.net",
    },
  ];

  const scrollRef = useRef(null);

  const handleMouseDown = (e) => {
    const slider = scrollRef.current;
    if (!slider) return;
    setIsDragging(true);
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleTouchStart = (e) => {
    const slider = scrollRef.current;
    if (!slider) return;
    setIsDragging(true);
    setStartX(e.touches[0].pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const slider = scrollRef.current;
    if (!slider) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2;
    slider.scrollLeft = scrollLeft - walk;
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const slider = scrollRef.current;
    if (!slider) return;
    const x = e.touches[0].pageX - slider.offsetLeft;
    const walk = (x - startX) * 2;
    slider.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <section className="w-full px-4 pt-20 md:pb-32 md:pt-36" id="partners">
      <div className="max-w-[1280px] mx-auto w-[96%]">
        <SectionTitle titleKey="partnerIntro" />
        <div className="flex flex-wrap items-center text-left">
          <p className="text-sm sm:text-base md:text-lg">
            {t("partnerDescription", { company: t("retrust") })}
          </p>
        </div>
        <div className="w-full mt-4">
          {/* PC/Tablet Version */}
          <div className="hidden md:block w-full">
            <div className="w-full mt-20 flex flex-col gap-16">
              {/* Business Partner Section */}
              <div className="flex items-start w-full">
                <p className="w-[15%] text-xl font-medium">
                  {t("businessPartner")}
                </p>
                <div className="w-[85%] flex">
                  <div className="relative w-[3px] h-auto self-stretch mx-8">
                    <div className="absolute top-0 left-0 w-full h-16 bg-[#579d1d]"></div>
                    <div className="absolute top-16 left-0 w-full h-[calc(100%-4rem)] bg-slate-400"></div>
                  </div>
                  <div className="flex flex-wrap gap-5">
                    {partnersData.business.map((partner, index) => (
                      <div
                        key={index}
                        className="border rounded-lg p-6 flex items-center justify-center w-[230px] h-[130px]"
                      >
                        <img
                          src={`/images/${partner}`}
                          alt={partner}
                          className={`object-contain`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Incubation Section */}
              <div className="flex items-start w-full">
                <p className="w-[15%] text-xl font-medium">
                  {t("businessIncubation")}
                </p>
                <div className="w-[85%] flex">
                  <div className="relative w-[3px] h-auto self-stretch mx-8">
                    <div className="absolute top-0 left-0 w-full h-16 bg-[#579d1d]"></div>
                    <div className="absolute top-16 left-0 w-full h-[calc(100%-4rem)] bg-[#ced1d4]"></div>
                  </div>
                  <div className="flex flex-wrap gap-5">
                    {partnersData.incubation.map((partner, index) => (
                      <div
                        key={index}
                        className="border rounded-lg p-5 flex items-center justify-center w-[230px] h-[130px]"
                      >
                        <img
                          src={`/images/${partner}`}
                          alt={partner}
                          className={`object-contain ${
                            isSquareLogo(partner)
                              ? "w-full max-h-full "
                              : "w-full max-h-full"
                          }`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile Version */}
          <div className="block md:hidden">
            <div className="w-full overflow-x-auto scrollbar-hide">
              <ul className="w-full flex items-center min-w-max">
                {filterButtons.map(({ id, label }) => (
                  <li
                    key={id}
                    className={`border py-2 px-3.5 text-sm md:text-base rounded-2xl text-center cursor-pointer whitespace-nowrap mx-1 ${
                      activeFilter === id ? "border-[#579d1d]" : ""
                    }`}
                    onClick={() => setActiveFilter(id)}
                  >
                    {label}
                  </li>
                ))}
              </ul>
            </div>

            <div
              ref={scrollRef}
              className="mt-6 overflow-x-auto scrollbar-hide touch-pan-x"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              style={{
                scrollBehavior: "smooth",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <div className="flex gap-4 min-w-max p-2">
                {partnersData[activeFilter].map((partner, index) => (
                  <div
                    key={index}
                    className="border rounded-lg p-4 flex items-center justify-center min-w-[160px] h-[100px]"
                    style={{ userSelect: "none" }}
                  >
                    <img
                      src={`/images/${partner}`}
                      alt={partner}
                      className={`object-contain ${
                        isSquareLogo(partner) ? "w-28 h-16" : "w-28 h-16"
                      }`}
                      draggable="false"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-20">
          <SectionTitle titleKey="participatingNetwork" />
          <div className="flex flex-wrap items-center text-left">
            <p className="text-sm sm:text-base md:text-lg">
              {t("networkDescription", { company: t("retrust") })}
            </p>
          </div>
        </div>

        <div className="mt-10 pb-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {networks.map((network, index) => (
              <NetworkCard
                key={index}
                image={network.image}
                descriptionKey={network.descriptionKey}
                link={network.link}
              />
            ))}
          </div>
        </div>
      </div>

      <style jsx global>{`
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </section>
  );
}

export default Partner;
