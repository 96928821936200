export default {
  intro: "Introduction",
  mission: "Mission",
  services: "Services",
  news: "News",
  partners: "Partners",
  contact: "Contact",
  korean: "Korean",
  english: "English",
  bannerTitle: "Connecting the real world with blockchain",
  bannerPoint1: "The value of decentralization in traditional industries",
  button: "Go to InsureTrust",
  learnMore: "Learn More",
  ourMission: "Our Mission,",
  connectingMoreWorlds: "Connects Real World To Web3",
  beyondReality: "Going Beyond Reality",
  connectingRealWorldWeb3: "Connecting the real world and Web3",
  redefiningLimits: "Redefining Limits",
  utilizingDecentralization: "Utilizing the essence of decentralization",
  creatingFuture: "Creating the Future",
  forSustainableFuture: "For a sustainable future",
  ourService: "Our Services",
  fareTrustSubtitle: "Travel guides available for 3,500 cities worldwide",
  fareTrustDescription: "",
  insuRETrustSubtitle: "RE Trust Product",
  insuRETrustDescription: "",
  blockchainSolution: "Blockchain Solution",
  blockchainSolutionSubtitle:
    "EVM Mainnet, BaaS (Blockchain as a Service), NFT Certificate Issuance",
  walletVerificationSolutionSubtitle: "Digital Asset Protection Solution",
  akamaiCloud: "Akamai Cloud",
  akamaiCloudSubtitle: "Low-Cost High-Performance Cloud for Blockchain",
  nodeValidating: "Node Validating",
  nodeValidatingSubtitle: "Pursuing the intrinsic value of decentralization",
  nodeValidatingDescription:
    "Re Trust understands the power of blockchain decentralized systems. Based on operating major mainnet nodes worldwide, we are preparing for the future by paving the way for industries to expand into web3.",
  blockchainConsulting: "Blockchain Consulting",
  blockchainConsultingSubtitle:
    "Designing the next world after digital transformation",
  blockchainConsultingDescription:
    "RE Trust guides various companies in their transition to web3 and fosters the growth of startups. Based on diverse experiences, we identify points for change and provide customized roadmaps.",
  retrustNews: "RE Trust News",
  news1Content: "Retrust invited to web3 in busan",
  news2Content: "Retrust web3 in busan concluded",
  news3Content: "Retrust and GroundX hold web3 in BUSAN",
  news4Content:
    "Meritz Fire & Marine Insurance signs MOU with blockchain company Retrust",
  news5Content: "Start-up NEST 15th IR Demo Day",
  news6Content:
    "Retrust, selected for the Credit Guarantee Fund's 'Little Penguin' program",
  retrust: "RETrust",
  news7Content:
    "KBIPA Successfully Concludes the 8th Web 3.0 Leaders Forum for 2024",
  news8Content:
    "RETrust Wins Grand Prize at BWB 2024 Demo Day, Leading Innovation with Blockchain Insurance Platform",
  news9Content:
    "Busan City Shares Achievements and Vision for 2024 Blockchain Business Support Programs",
  news10Content:
    "Busan REtrust introduces Korea's first 'post-departure enrollment' overseas travel insurance... 'OK even if you forget at the airport",
  news11Content:
    "TAFISA KOREA Signs MOU with REtrust for Global Carbon Neutrality",
  news12Content:
    "RETrust Employees Make Charitable Donation for 'Hope 2025' Neighbors Support Campaign",
  retrust: "RETrust",
  partnerIntro: "Partner Introduction,",
  partnerDescription: "Introducing our partners with Ritrust",
  participatingNetwork: "Participating Networks",
  networkDescription: "{{company}} is building the web3 ecosystem.",
  businessPartner: "Business Partnership",
  serviceCollaboro: "Service Collaboration",
  businessIncubation: "Government and Public Institutions",
  networkBIFC: "BIFC Blockchain Resident Company Co-Chairman",
  networkGRID: "GRID NFT Alliance Member",
  networkPRENET: "24-year Prenet Operator",
  networkKITA: "Full Member of KITA",
  footerDescription:
    "Web3 solutions, mainnet node validation consulting. If you have any inquiries or partnership proposals, please contact us via the email below.",
  inquiry: "Inquiry",
  email: "Email",
  linkedin: "LinkedIn",
  companyInfo: "RE Trust Co., Ltd. CEO: Wooseok Jang",
  callInfo: "Contact Us: +82-2-1566-3305",
  businessNumber: "Business Registration Number: 370-88-02749",
  addressTitle: "address",
  address:
    "8F, 40, Munhyeongeumyung-ro, Nam-gu, Busan, Republic of Korea (Busan International Finance Center)",
  address2: "IT Venture Tower, 135 Jungdae-ro, Songpa-gu, Seoul, South Korea",
  orderNum:
    "E-commerce Business Registration Number: No. 2024-Busan Nam-gu-0464",
  copyright: "© 2023 RE Trust Co., Ltd. All rights reserved",
  // 아카마이 네비
  newStandard: "New Standard",
  uniqueness: "Uniqueness",
  planInfo: "Plan Info",
  ko: "korean",
  en: "english",
  blockChain: "BlockChain Solution Page",

  // 아카마이 네비

  // 아카마이 베너
  akamaiBannerAlt: "Akamai cloud service banner image",
  freeCloudUsage: "Use Industry-Leading Cloud Services for Free",
  akamaiCloudIntro1: "Akamai Connected Cloud from the Global #1 CDN Company",
  akamaiCloudIntro2: "Get a free usage opportunity from Retrust",
  useFree: "Use for Free",
  // 아카마이 베너

  //claudeSelect
  cloudStandardTitle: "New Standard for Cloud Selection",
  computingTitle: "Diverse Computing Options",
  computingDesc: "We offer a wide range of computing power.",
  databaseTitle: "Convenient SaaS Database",
  databaseDesc: "We support commonly used SQL and NoSQL databases.",
  storageTitle: "Flexible and Reliable Storage Plan",
  storageDesc:
    "We provide ample storage space at reasonable prices without worry.",
  securityTitle: "Robust Security and Network Services",
  securityDesc: "We offer basic free security solutions + various options.",
  computingOptionAlt: "Computing option {{number}}",
  //claudeSelect

  //AkamaiPlan
  akamaiLinodePlanGuide: "Akamai Linode Plan Guide",
  cloudServiceConsultation:
    "Get a consultation and use credits for the latest cloud service with the best price-performance ratio in the industry",
  dedicatedCPU: "Dedicated CPU",
  dedicatedCPUDesc:
    "Dedicated CPU instances run on their own CPU cores in the cloud computing infrastructure. They do not share processors with other instances.",
  sharedCPU: "Shared CPU",
  sharedCPUDesc:
    "The economical Shared CPU plan is ideal for general workloads and includes a sufficient amount of free bundled transfer.",
  highMemory: "High memory",
  highMemoryDesc:
    "High memory instances are cost-effective instances that can run memory-intensive applications that need more RAM on dedicated CPUs without adding storage or vCPUs.",
  gpuRTX6000: "GPU RTX6000x1 GPU",
  gpuRTX6000Desc:
    "Use on-demand GPUs for parallel processing workloads such as machine learning, scientific computing, and video processing.",
  premium2CPU: "Premium 2CPU",
  premium2CPUDesc:
    "Premium instances provide a minimum hardware model that can deliver consistent performance on dedicated resources.",
  perMonth: "/month ~",
  getConsultationAndUse: "Get Consultation and Use",
  //AkamaiPlan

  //AkamaiMerit
  differentiation: "Differentiation from Other Services",
  akamaiGraphAlt: "Akamai service comparison graph",
  //AkamaiMerit

  //blockCahinBanner
  blockchainSolutionTitle: "Introduction to ReTrust Solutions",
  blockchainSolutionSubtitle: "Introduce blockchain solutions.",
  blockchainSolutionDescription:
    "Easy to apply and provide more benefits to your customers.",
  //blockCahinBanner
  retrustSolution: "RETrust Solution",
  //OpenGuardNFT
  NFT_title:
    "It's a solution based on a public blockchain that integrates with the Klip wallet. You can safely store and share securities, personal information, and certificates that need verification.",
  NFT_Authentication:
    "Authentication through NFT ownership without unnecessary logins",
  NFT_Authentication_desc:
    "We improve user experience with a streamlined authentication process using NFTs",
  NFT_privateTitle1: "In a private area",
  NFT_privateTitle2:
    "we offer management of various types of information and certificate storage",
  NFT_privateTitle_desc:
    "We protect personal data with a secure and efficient information management system.",
  MemberShip_title: "Membership recommendation feature",
  MemberShip_desc: "Share it easily with anyone.",
  MemberShip_subDesc:
    "The recipient can receive benefits such as membership enrollment or community participation.",
  Membership_benefits_title: "Membership benefits feature",
  Membership_benefits_desc:
    "Feature that provides various benefits, such as admission tickets and invitations, to customers who hold NFTs",
  Membership_benefits_subDesc:
    "Enhance customer loyalty and increase brand value with special benefits",
  //OpenGuardNFT

  //Mainnet
  Mainnet_Title:
    "We provide customized solutions for companies looking to utilize blockchain networks",
  Mainnet_First_MenuTitle: "Excellent scalability",
  Mainnet_First_item1: "EVM-compatible mainnet",
  Mainnet_First_item2: "Non-EVM connectivity",
  Mainnet_First_item3: "CCIP-based mainnet integration (TBU)",
  Mainnet_Second_MenuTitle: "High security",
  Mainnet_Second_item1: "Multi-signature wallet",
  Mainnet_Second_item2: "Audit trail",
  Mainnet_Second_item3: "Smart contract security audit",
  //Mainnet

  //SmartIdentity
  SmartIdentity_Title:
    "You don't need to develop complex smart contracts yourself. Easily build tamper-proof verification and authentication services using the API provided by Retrust.",
  ProofContents_desc: "A safe and reliable identity verification system",
  FeatureCard2_title: "Blockchain Network Integration",
  FeatureCard2_desc:
    "Seamless integration with efficient and secure blockchain networks",
  FeatureCard3_title: "Tamper-proof Proof",
  FeatureCard4_title: "Authentication, Notarization, and Contract Services",
  //SmartIdentity

  //Certificate
  Certificate_SubTitle:
    "You can conveniently obtain a certified date and securely store it in your wallet without the hassle of visiting the local government office, registration office, or notary office.",
  FeatureSection_1nd_Title:
    "Convenient Certified Date in the Palm of Your Hand",
  FeatureSection_1nd_desc:
    "You can instantly obtain a certified date conveniently using your smartphone or PC from anywhere.",
  FeatureSection_2nd_Title: "Prevent Unnecessary Disputes",
  FeatureSection_2nd_desc:
    "Easily store your certified date documents in the KakaoTalk Klip wallet and submit them immediately when needed. No more worries about loss or disputes!",
  FeatureSection_3nd_Title: "Blockchain, the Network of Trust",
  FeatureSection_3nd_desc:
    "All details of the original document and certified date are accurately recorded on the blockchain, providing high trust in the authenticity and clarity of the certificate.",

  //Certificate
};
