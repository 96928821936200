export default {
  intro: "소개",
  mission: "미션",
  services: "서비스",
  news: "소식",
  partners: "파트너",
  contact: "연락하기",
  korean: "한국어",
  english: "영어",
  bannerTitle: "실세계와 블록체인을 연결하다",
  bannerPoint1: "전통산업에 탈중앙화의 가치를 더해 세상을 변화시킵니다",
  learnMore: "자세히 보기",
  button: "인슈어트러스트 보러가기",
  ourMission: "우리의 미션,",
  connectingMoreWorlds: "더 많은 세상과의 연결",
  beyondReality: "현실을 뛰어넘다",
  connectingRealWorldWeb3: "실세계와 웹3를 연결",
  redefiningLimits: "한계를 재정의하다",
  utilizingDecentralization: "분산화의 본질을 활용",
  creatingFuture: "미래를 창조한다",
  forSustainableFuture: "지속 가능한 미래를 위하여",
  ourService: "우리의 서비스",
  achieved: "이루고 있는 것들",
  services: "서비스",
  solutions: "솔루션",
  skills: "기술",
  fareTrustSubtitle: "세계 3500개 도시 여행 가이드 제공",
  fareTrustDescription: "",
  insuRETrustSubtitle: "여행자 보험 가입 및 청구 플랫폼",
  insuRETrustDescription: "",
  blockchainSolution: "블록체인 솔루션",
  blockchainSolutionSubtitle: "EVM메인넷, BaaS, NFT증서발행",
  walletVerificationSolution: "Wallet 검증 솔루션",
  walletVerificationSolutionSubtitle: "디지털자산 보호 솔루션",
  akamaiCloud: "아카마이 클라우드",
  akamaiCloudSubtitle: "블록체인을 위한 저가 고성능 클라우드",
  nodeValidating: "노드 벨리데이팅",
  nodeValidatingSubtitle: "분산화의 본질적 가치를 추구",
  nodeValidatingDescription:
    "전 세계 주요 메인넷 노드 운영을 기반으로 산업이 web3로 확장할 수 있는 길을 마련하여 미래를 준비하고 있습니다.",
  blockchainConsulting: "블록체인 컨설팅",
  blockchainConsultingSubtitle: "디지털 전환 다음 세상을 설계",
  blockchainConsultingDescription:
    "RE Trust는 다양한 기업의 web3로의 전환을 안내하며, 스타트업의 성장을 육성합니다. 다양한 경험을 기반으로 변화를 위한 포인트를 찾아내고, 맞춤형 로드맵을 안내합니다.",
  retrustNews: "리트러스트 소식",
  news1Content: "리트러스트 web3 in busan 초청",
  news2Content: "리트러스트 web3 in busan 종료",
  news3Content: "KISA, 부산 블록체인 특화 클러스터 조성 ···총 62억 지원",
  news4Content: "메리츠화재, 블록체인 기업 리스트러스와 MOU",
  news5Content: "스타트업 네스트((Start-up NEST) 15기 IR 데모데이",
  news6Content: "리트러스트, 신용보증기금 '리틀펭귄' 프로그램 선정",
  news7Content: "KBIPA, 2024년도 제8차 Web 3.0 리더스포럼 성료",
  news8Content: "BWB 2024 데모데이 대상 리트러스트, 블록체인 보험 … ",
  news9Content: "부산시, 2024년 블록체인 기업지원사업 성과·비전 공유",
  news10Content:
    "부산 리트러스트, 국내 첫 '출국 후 가입' 해외여행보험 등장...공항서 깜빡해도 OK",
  news11Content: "TAFISA KOREA, 리트러스트와 글로벌 탄소중립 위한 MOU 체결",
  news12Content: "리트러스트 임직원 일동, 희망 2025 이웃돕기 성금",
  retrust: "리트러스트",
  partnerIntro: "파트너",
  partnerDescription: "{{company}}와 함께하는 파트너를 소개합니다.",
  businessPartner: "사업 파트너",
  serviceCollaboro: "서비스 협력",
  businessIncubation: "정부 및 공공기관",
  participatingNetwork: "참여 네트워크",
  networkDescription: "{{company}}는 web3 에코를 구축해 나갑니다.",
  networkBIFC: "BIFC 블록체인 입주기업 공동회장",
  networkGRID: "그리드 NFT 얼라이언스 멤버",
  networkPRENET: "24년 프리넷 운영사",
  networkKITA: "한국무역협회 정회원",
  footerDescription:
    "웹3 솔루션, 메인넷 노드 검증 컨실팅. 문의사항이나 파트너십 제안이 있으시다면, 아래의 이메일로 연락주세요.",
  inquiry: "문의",
  email: "이메일",
  linkedin: "링크드인",
  companyInfo: "주식회사 리트러스트 대표이사: 장우석",
  callInfo: "고객센터: 1566-3305",
  businessNumber: "사업자등록번호: 370-88-02749",
  addressTitle: "주소",
  address: "부산광역시 남구 문현금융로 40, 8층 부산국제금융센터",
  address2: "서울특별시 송파구 중대로 135 아이티벤처타워 서관 10층",
  orderNum: "통신판매업신고번호 제 2024-부산남구-0464 호",
  copyright: "© 2023 RE Trust Co., Ltd. All rights reserved",
  // 아카마이 베너
  akamaiBannerAlt: "아카마이 클라우드 서비스 배너 이미지",
  freeCloudUsage: "업계 최고 수준의 클라우드를 무료로 사용하세요",
  akamaiCloudIntro1: "CDN 글로벌 1위 기업의 아카마이 커넥티드 클라우드",
  akamaiCloudIntro2: "리트러스트에서 무료 사용 기회를 드립니다.",
  useFree: "무료 이용하기",
  // 아카마이 베너

  // 아카마이 네비
  newStandard: "새로운 기준",
  uniqueness: "타서비스와의 차별성",
  planInfo: "플랜안내",
  ko: "한국어",
  en: "영어",
  blockChain: "블록체인 솔루션 페이지",
  // 아카마이 네비
  //claudeSelect
  cloudStandardTitle: "클라우드 선택의 새로운 기준",
  computingTitle: "다양한 선택의 컴퓨팅",
  computingDesc: "다양한 범위의 컴퓨팅 파워를 제공합니다.",
  databaseTitle: "편리한 SaaS형 데이터베이스",
  databaseDesc: "범용적으로 사용되는 SQL과 NoSQL 데이터베이스를 지원합니다.",
  storageTitle: "유연하고 안정적인 스토리지 플랜",
  storageDesc: "합리적인 가격에 걱정없는 넉넉한 저장공간을 제공합니다.",
  securityTitle: "견고한 보안과 네트워크 서비스",
  securityDesc: "기본 무료 보안 솔루션 + 다양한 옵션 제공 합니다.",
  computingOptionAlt: "컴퓨팅 옵션 {{number}}",
  //claudeSelect

  //AkamaiPlan
  akamaiLinodePlanGuide: "아카마이 리노드 플랜 안내",
  cloudServiceConsultation:
    "업계 최고의 가격 대비 성능을 갖춘 최신 클라우드 서비스 상담받고 크레딧 사용하기",
  dedicatedCPU: "Dedicated CPU",
  dedicatedCPUDesc:
    "전용 CPU 인스턴스는 클라우드 컴퓨팅 인프라의 자체 CPU 코어에서 실행됩니다. 다른 인스턴스와 프로세서를 공유하지 않습니다.",
  sharedCPU: "Shared CPU",
  sharedCPUDesc:
    "경제적인 Shared CPU 요금제는 일반 워크로드에 이상적이며, 충분한 양의 무료 번들 전송을 포함합니다.",
  highMemory: "High memory",
  highMemoryDesc:
    "대용량 메모리 인스턴스는 스토리지나 vCPU를 추가하지 않고 RAM이 더 필요한 메모리 집약적인 애플리케이션을 전용 CPU에서 비용 효율적으로 실행할 수 있는 인스턴스입니다.",
  gpuRTX6000: "GPU RTX6000x1 GPU",
  gpuRTX6000Desc:
    "머신 러닝, 과학 컴퓨팅, 비디오 프로세싱 등 병렬 프로세싱 워크로드에 온디맨드 GPU를 사용합니다.",
  premium2CPU: "Premium 2CPU",
  premium2CPUDesc:
    "프리미엄 인스턴스는 전용 리소스에서 일관된 성능을 발휘할 수 있는 최소 하드웨어 모델을 제공합니다.",
  perMonth: "/월 ~",
  getConsultationAndUse: "상담받고 이용하기",
  //AkamaiPlan

  //AkamaiMerit
  differentiation: "타서비스와의 차별성",
  akamaiGraphAlt: "아카마이 서비스 비교 그래프",
  //AkamaiMerit

  //blockCahinBanner
  blockchainSolutionTitle: "리트러스트 솔루션 소개",
  blockchainSolutionSubtitle: "블록체인 솔루션을 도입해 보세요.",
  blockchainSolutionDescription:
    "쉽게 적용하고, 고객에게 더 많은 혜택을 제공하세요.",
  //blockCahinBanner
  retrustSolution: "리트러스트 솔루션",

  //OpenGuardNFT
  NFT_title:
    "퍼블릭 블록체인 기반으로 Klip지갑과 연동되는 솔루션 입니다. 증권, 개인정보, 검증이 필요한 증명서를 안전하게 보관하고 공유할 수 있습니다.",
  NFT_Authentication: "불필요한 로그인이 없는 NFT보유를 통한 인증",
  NFT_Authentication_desc:
    "NFT를 통한 간편한 인증 프로세스로 사용자 경험을 개선합니다.",
  NFT_privateTitle1: "프라이빗 영역으로",
  NFT_privateTitle2: "다양한 정보의 관리 제공 ,증서 보관",
  NFT_privateTitle_desc:
    "안전하고 효율적인 정보 관리 시스템으로 개인 데이터를 보호합니다.",
  MemberShip_title: "멤버십 추천기능 ",
  MemberShip_desc: "누구에게나 쉽게 전달하세요. ",
  MemberShip_subDesc:
    "전달받은 사람은 멤버십 가입 또는 커뮤니티 참여 혜택을 받을 수 있습니다.",
  Membership_benefits_title: "멤버십 혜택기능",
  Membership_benefits_desc:
    "NFT를 보유한 고객에게 입장권 초대권 등 다양한 혜택 제공기능",
  Membership_benefits_subDesc:
    "특별한 혜택으로 고객 충성도를 높이고 브랜드 가치를 향상시킵니다.",
  //OpenGuardNFT

  //Mainnet
  Mainnet_Title:
    "블록체인 네트워크를 활용하고자 하는 기업들에게 맞춤형 솔루션을 제공합니다.",
  Mainnet_First_MenuTitle: "우수한 확장성",
  Mainnet_First_item1: "EVM 호환 메인넷",
  Mainnet_First_item2: "Non-EVM 연결",
  Mainnet_First_item3: "CCIP 기반 메인넷 연동(TBU)",
  Mainnet_Second_MenuTitle: "높은 보안성",
  Mainnet_Second_item1: "멀티시그 지갑",
  Mainnet_Second_item2: "감사 추적",
  Mainnet_Second_item3: "스마트 컨트랙트 보안 감사",
  //Mainnet
  //SmartIdentity
  SmartIdentity_Title:
    "어려운 스마트 컨트랙트, 직접 개발하지 않아도 됩니다. 리트러스트가 제공하는 API를 통해 위변조 방지 검증 및 인증 서비스를 쉽게 개발할 수 있습니다",
  ProofContents_desc: "안전하고 신뢰할 수 있는 신원 증명 시스템",
  FeatureCard2_title: "블록체인 네트워크 통합",
  FeatureCard2_desc: "효율적이고 안전한 블록체인 네트워크와의 원활한 통합",
  FeatureCard3_title: "위변조 방지 증명",
  FeatureCard4_title: "인증, 공증, 계약 서비스",
  //SmartIdentity

  //Certificate
  Certificate_SubTitle:
    "번거롭게 주민센터와 등기소, 공증사무소를 방문하지 않고도 편리하게 확정일자를 받고 지갑에 안전하게 보관할 수 있습니다.",
  FeatureSection_1nd_Title: "내 손 안의 간편한 확정일자",
  FeatureSection_1nd_desc:
    "어디에서든 편리하게 스마트폰이나 PC를 이용하여 즉시 확정일자를 발급받으실 수 있습니다.",
  FeatureSection_2nd_Title: "불필요한 분쟁을 예방할 수 있어요",
  FeatureSection_2nd_desc:
    "발급받은 확정일자 서류를 카카오톡의 Klip 지갑에 편리하게 보관하고 필요할 때 바로 제출. 더 이상의 분실과 분쟁 걱정 끝!",
  FeatureSection_3nd_Title: "블록체인, 신뢰의 네트워크",
  FeatureSection_3nd_desc:
    "원본 문서와 확정일자의 모든 내용이 블록체인에 정확하게 기록되어, 증서의 진위와 명확성에 대한 높은 신뢰를 제공합니다.",
  //Certificate
};
